@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fascinate&family=Limelight&family=Luckiest+Guy&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fascinate&family=Limelight&family=Luckiest+Guy&family=Poetsen+One&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url("https://fonts.googleapis.com/css?family=Merriweather:400,700");

body {
  margin: 0;
  background-image: url('./Images/Optimiserable_Background.jpg');
  background-size: cover;
  background-position: center;
  padding: 0;
}

button{
  border:none;
}
.secondary-color{
color: #E4CC5D;
}

.App{
  text-align: center;
  /* background: rgb(67,65,65);
  background: linear-gradient(180deg, rgba(67,65,65,1) 0%, rgba(35,35,46,1) 35%, rgba(17,18,19,1) 100%); */
  height: 100vh;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(31, 31, 31, 0.3);
}

h1{
  font-family: "Luckiest Guy";
  color: #6A3276;
  font-size: 42px;
}

h2{
  color: #6A3276;
  font-family: "Poetsen One", sans-serif;
}

.inputContainer{
  display: flex;
  justify-items: center;
  justify-content: center;
  align-content: center;
  /* border: red 1px solid; */
}

.flex1 {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #000;  */
  width: 100%;
}

.flex2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #000;  */
  width: 100%;
}




form .formField{
  width: 100%;
  height: 100%;
}
form input {
  border: 3px solid #6A3276;
  border-radius: 5px;
  height: 100%;
  line-height: normal;
  color: #6A3276;
  display: block;
  width: 100%;
  box-sizing: border-box;
  user-select: auto;
  font-size: 16px;
  padding: 0 6px;
  padding-left: 12px;
}

form input:focus {
  border: 3px solid #E4CC5D;
}