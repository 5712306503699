
.button{
 
  display: inline-block;
  outline: 0;
  cursor: pointer;
  border: 2px solid #6A3276;
  border-radius: 3px;
  color: #fff;
  background: #6A3276;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  padding: 12px 20px;
  width: 100%;
  text-align:center;
  transition-duration: .15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

/* .button:hover{
  color: #000;
  background: rgb(255, 218, 87);
} */


button:disabled {
  background-color: gray;
  color: white;
  cursor: not-allowed;
  border:none
}