.navbar{
  
  align-items: center;
  
  position: fixed;
  height: 100px;
  top: 0;
  width: 100%;
  left: 0;
}

.socialContainer{
  width: 100%;
  height: 70px;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
}
.socialLink:hover{
color: #ebbc52;
}
.socialLink{
color: #6A3276;
}
.OpLogo{
  height: 70px;
  /* border: 1px solid red; */
}

.logoContainer{
  padding: 20px;
}

.navbarToggler {
  position: absolute;
  top: 20px;
  padding: 0;
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 1.6rem;
  background: #703b71;
  justify-content: center;
  align-items: center;
  display: none;
  color: #ebbc52;
  left: 100%;
}

.navSidebar {
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(-100%)  ;
  height: 100vh;
  width: 300px;
  background: #703b71;
  border-right: 1px solid #ebbc52;
  z-index: 10;
  -webkit-transition: all .4s ease-out;
  transition: all .4s ease-out;
  display: none;
}






@media only screen and (max-width: 767px){
  .navbarToggler{
      display:flex;
  }
  .navSidebar {
    width: 250px;
    display: block;
    transform: translateX(-100%)  ;
}
} 


@media only screen and (max-width: 1024px) and (min-width: 768px){
.navSidebar {
  width: 260px;
  transform: translateX(-100%);
  display: block;
}
.navbarToggler{
  display:flex;
}

}





.showNav{
  transform:translateX(0) !important;
}

.navInner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
  
}

.navMenu {
  width: 100%;
  padding: 15px 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navMenu li {
  list-style: none;
  text-align: center;
  display: block;
}

.navMenu li a {
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  padding: 5px 0;
  color: #ebbc52;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.navInner{
  color: #ebbc52;
}

.navMenu li:not(:last-child) {
  margin-bottom: 1px;
}

.navMenu li a.active {
  color: #fff;
}


.navCopyright {
  display: block;
  width: 100%;
  color: #ebbc52;
  text-align: center;
  margin-bottom: 0;
  font-size: 1rem;
  padding: 16px 0;
  border-top: 1px solid #ebbc52;
  font-weight: 300;
 
}